<template>
  <div
    class="pt-3"
    :class="$style.container"
    @click.prevent="toGrading(item.id)"
  >
    <div class="bg-success" :class="$style.status"></div>
    <div class="d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4">
      <div class="mr-auto">
        <div class="font-weight-bold font-size-18 text-dark">
          {{ item.slot.kela.tingkat || item.slot.kela.level.nama }} | {{ item.slot.mata_pelajaran.nama }}
        </div>
        <div :class="$style.subtopik">
          {{ item.topik ? item.topik.subtopik : "-" }}
        </div>
      </div>
      <div class="ml-1 text-primary">
        <a-icon
          :type="tipeAssignment"
          :style="{
            borderRadius: '4px',
            color: '#1B55E3',
            padding: '5px',
            background: `rgba(234, 240, 252, 0.9)`,
            fontSize: '1.5rem',
          }"
        />
      </div>
    </div>
    <hr />
    <div class="d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4">
      <div class="mr-auto">
        <div class="font-weight-bold font-size-14 text-dark">
          {{ item.slot.kela.tingkat || item.slot.kela.level.nama }}-{{ item.slot.kela.simbol }}
        </div>
      </div>
      <div class="ml-1" :style="{ color: cardColor }">
        {{ checkDueDate(item.tugas_deadline2) }}
        <a-icon type="clock-circle" class="ml-1" />
      </div>
    </div>
    <div class="pb-3 pl-4 pr-4">
      <a-progress
        type="line"
        :percent="percentGraded"
        :showInfo="false"
        :strokeWidth="12"
        strokeColor="#40B983"
      />
    </div>
    <div class="d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4">
      <div class="mr-auto">
        <div class="font-size-12">
          <span class="text-dark font-weight-bold font-size-18">{{
            item.tugas_submitted
          }}</span>
          Submited / {{ item.tugas_count }}
        </div>
      </div>
      <div class="ml-1">
        <div class="font-size-12">
          <span class="text-dark font-weight-bold font-size-18">{{
            item.tugas_graded
          }}</span>
          Graded
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['item'],
  data() {
    return {
      cardColor: '#051931',
      moment,
    }
  },
  methods: {
    checkDueDate(tglDueDate) {
      const hariIni = moment().locale('id')
      const dueDate = moment(tglDueDate)
      let rangeDays = dueDate.diff(hariIni, 'days')
      const rangeWeeks = dueDate.diff(hariIni, 'weeks')
      if (rangeWeeks < 1) {
        if (rangeDays <= 2) {
          this.cardColor = '#FF0102'
          if (rangeDays <= 1) {
            rangeDays = 0
            return rangeDays + ' day'
          }
          return rangeDays + ' days'
        } else {
          return rangeDays + ' days'
        }
      } else if (rangeWeeks === 1) {
        return rangeWeeks + ' week'
      } else {
        return rangeWeeks + ' weeks'
      }
    },
    toGrading(id) {
      this.$router.push({ name: 'Grading Assignments Teacher', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Assignments Teacher'],
      })
    },
  },
  computed: {
    percentGraded() {
      const totalSubmitted = this.item.tugas_submitted
      const totalGraded = this.item.tugas_graded
      const hasil = (totalGraded / totalSubmitted) * 100
      return hasil
    },
    tipeAssignment() {
      if (this.item.tipe_assignment === 'File Upload') {
        return 'paper-clip'
      } else if (this.item.tipe_assignment === 'Essay') {
        return 'align-left'
      } else if (this.item.tipe_assignment === 'Multiple Choice') {
        return 'unordered-list'
      } else {
        return ''
      }
    },
    subtopik() {
      return this.item.topik?.subtopik || '-'
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>

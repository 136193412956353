var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-3",class:_vm.$style.container,on:{"click":function($event){$event.preventDefault();return _vm.toGrading(_vm.item.id)}}},[_c('div',{staticClass:"bg-success",class:_vm.$style.status}),_c('div',{staticClass:"d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4"},[_c('div',{staticClass:"mr-auto"},[_c('div',{staticClass:"font-weight-bold font-size-18 text-dark"},[_vm._v(" "+_vm._s(_vm.item.slot.kela.tingkat || _vm.item.slot.kela.level.nama)+" | "+_vm._s(_vm.item.slot.mata_pelajaran.nama)+" ")]),_c('div',{class:_vm.$style.subtopik},[_vm._v(" "+_vm._s(_vm.item.topik ? _vm.item.topik.subtopik : "-")+" ")])]),_c('div',{staticClass:"ml-1 text-primary"},[_c('a-icon',{style:({
          borderRadius: '4px',
          color: '#1B55E3',
          padding: '5px',
          background: `rgba(234, 240, 252, 0.9)`,
          fontSize: '1.5rem',
        }),attrs:{"type":_vm.tipeAssignment}})],1)]),_c('hr'),_c('div',{staticClass:"d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4"},[_c('div',{staticClass:"mr-auto"},[_c('div',{staticClass:"font-weight-bold font-size-14 text-dark"},[_vm._v(" "+_vm._s(_vm.item.slot.kela.tingkat || _vm.item.slot.kela.level.nama)+"-"+_vm._s(_vm.item.slot.kela.simbol)+" ")])]),_c('div',{staticClass:"ml-1",style:({ color: _vm.cardColor })},[_vm._v(" "+_vm._s(_vm.checkDueDate(_vm.item.tugas_deadline2))+" "),_c('a-icon',{staticClass:"ml-1",attrs:{"type":"clock-circle"}})],1)]),_c('div',{staticClass:"pb-3 pl-4 pr-4"},[_c('a-progress',{attrs:{"type":"line","percent":_vm.percentGraded,"showInfo":false,"strokeWidth":12,"strokeColor":"#40B983"}})],1),_c('div',{staticClass:"d-flex flex-nowrap align-items-center pb-3 pl-4 pr-4"},[_c('div',{staticClass:"mr-auto"},[_c('div',{staticClass:"font-size-12"},[_c('span',{staticClass:"text-dark font-weight-bold font-size-18"},[_vm._v(_vm._s(_vm.item.tugas_submitted))]),_vm._v(" Submited / "+_vm._s(_vm.item.tugas_count)+" ")])]),_c('div',{staticClass:"ml-1"},[_c('div',{staticClass:"font-size-12"},[_c('span',{staticClass:"text-dark font-weight-bold font-size-18"},[_vm._v(_vm._s(_vm.item.tugas_graded))]),_vm._v(" Graded ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }